/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";

import { useWindowSize } from "../../../shared/hooks/windowSize";

import Star1 from "../../../static/backgrounds/Star1.png";
import Star2 from "../../../static/backgrounds/Star2.png";
import Star3 from "../../../static/backgrounds/Star3.png";
import Star1Small from "../../../static/backgrounds/Star1Small.png";
import Star2Small from "../../../static/backgrounds/Star2Small.png";
import Star3Small from "../../../static/backgrounds/Star3Small.png";

import "./index.css";

const DigitalTwinsSection = () => {
  const detectedItem = useRef();
  const { width } = useWindowSize();
  const [offset, setOffset] = useState(0);
  const [detected, setDetected] = useState(false);

  const setScroll = () => {
    setOffset(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  useEffect(() => {
    if (!detected) {
      detectScroll();
    }
  }, [offset]);

  const detectScroll = () => {
    if (detectedItem.current.offsetTop >= offset) {
      const top = detectedItem.current.offsetTop;

      if (top <= offset + 500) {
        setDetected(true);
        detectedItem.current.childNodes.forEach((child, index) => {
          child.childNodes[0].classList.add(
            "digital-twins-content-stars",
            `digital-twins-content-stars-${index}`
          );
        });
      }
    }
  };

  const stars = useMemo(
    () => [
      {
        src: width > 1100 ? Star1 : Star1Small,
        name: "IoT",
      },
      {
        src: width > 1100 ? Star2 : Star2Small,
        name: "AI/ML",
      },
      {
        src: width > 1100 ? Star3 : Star3Small,
        name: "Cloud",
      },
    ],
    [width]
  );

  return (
    <div className="digital-twins-page">
      <h1 className="digital-twins-title">The raise of digital twins</h1>
      <div className="digital-twins-secondary-text-container">
        <span className="digital-twins-secondary-text">
          A Human Digital Twin is a revolutionary technology creating a virtual
          representation that serves as the real-time digital counterpart of the
          human.
        </span>
      </div>
      <div className="digital-twins-technologies-container">
        <span className="digital-twins-technologies">
          Based on the Following Technologies
        </span>
      </div>
      <div ref={detectedItem} className="digital-twins-page-content">
        {stars.map(({ src, name }, index) => (
          <div key={index}>
            <div
              className={`digital-twins-content-hidden digital-twins-content-star-${
                index % 2 === 0 ? "odd" : "even"
              }`}
            >
              <img className="nodrag-noselect" src={src} alt="Star" />
            </div>
            <div
              className={`technologies-name-section-${
                index % 2 === 0 ? "odd" : "even"
              }`}
            >
              <span className="technologie-name">{name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalTwinsSection;
