import React, { useRef } from "react";

import Header from "../header";
import ProblemsSection from "./problemsSection";
import HumanityContent from "./humanitySection";
import SulotionSection from "./solutionSection";
import PublicHealthSection from "./publicHealthSection";
import DigitalTwinsSection from "./digitalTwinsSection";

import MouseDown from "../../static/backgrounds/mouse-down.png";
import DiagramBackground from "../../static/backgrounds/Group 2096.png";
import GraphicElementsHeader from "../../static/backgrounds/Graphic-Elements.png";

import "./index.css";

const Content = () => {
  const problemsPageRef = useRef(null);

  const learnMoreClick = () => {
    problemsPageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="content">
      <div className="first-section">
        <img
          className="header-graphic-elements nodrag-noselect"
          src={GraphicElementsHeader}
          alt="Graphic element"
        />
        <Header />
        <HumanityContent learnMoreClick={learnMoreClick} />
      </div>
      <div className="scroll-section">
        <img className="nodrag-noselect" src={MouseDown} alt="Scroll down" />
        <span className="first-section-footer">Scroll Down</span>
      </div>
      <div className="second-section">
        <ProblemsSection pageRef={problemsPageRef} />
        <img
          className="diagram-background-img nodrag-noselect"
          src={DiagramBackground}
          alt="DiagramBackground"
        />
      </div>
      <div className="third-section">
        <SulotionSection />
      </div>
      <div className="fourth-section">
        <DigitalTwinsSection />
      </div>
      <div className="fourth-section">
        <PublicHealthSection />
      </div>
    </div>
  );
};

export default Content;
