/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";

import "./index.css";

const Footer = () => {
  return (
    <div className="footer-page-container">
      <div className="footer-page">
        <div className="footer-page-header">
          <div className="footer-title-container">
            <h1 className="footer-title">become a step closer</h1>
            <span className="footer-description">
              Be a part of a lifechanging journey
            </span>
          </div>
          <div className="footer-content-container">
            <button
              onClick={() =>
                (window.location = "mailto:info@milies.net")
              }
              className="contact-us-btn footer-content-btns-config"
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className="footer-line" />
        <div className="footer-page-underline-section">
          <div className="footer-left-items">
            <span className="underline-txt footer-layout-copyright link-item nowrap">
              © {moment().year()} Milies Health, All rights reserved
            </span>
            <a className="underline-txt link-item nowrap">Privacy Policy</a>
            <a className="underline-txt link-item nowrap">Terms of Services</a>
          </div>
          <div className="footer-right-items">
            <a
              className="underline-txt link-item nowrap"
              href="tel:+49 178 7874884"
            >
              +49 178 7874884
            </a>
            <span className="underline-txt link-item nowrap">
              Luisenstraße 73, 80789 München, Germany
            </span>
            <a
              className="underline-txt link-item"
              href="mailto:info@milies.net"
            >
              info@milies.net
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
