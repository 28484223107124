import React from "react";

import EarthImg from "../../../static/backgrounds/earth.png";

import "./index.css";

const SulotionSection = () => {
  const options = [
    {
      text: "From Personalised Medicine to Precision Public Health",
    },
    {
      text: "Improved coordination of resources for healthcare delivery",
    },
    {
      text: "Enhanced access to care",
    },
    {
      text: "Disease outbreak control",
    },
  ];

  const croppedRectangle = (text, key) => (
    <div
      key={key}
      className={`croped-rect-container rect-container-${key} croped-rect-container-${
        key % 2 === 0 ? "odd" : "even"
      }`}
    >
      <div
        className={`cropped-rect-content-container cropped-rect-content-container-${
          key % 2 === 0 ? "odd" : "even"
        }`}
      >
        <span
          className={`cropped-rect-text cropped-rect-${
            key % 2 === 0 ? "odd" : "even"
          }`}
        >
          {text}
        </span>
      </div>
    </div>
  );

  return (
    <div className="public-health-page">
      <div>
        <h1 className="public-health-title">Public Health</h1>
        <span className="public-health-description">
          Digital Twins for Improved Healthcare delivery
        </span>
      </div>
      <div className="public-health-content-container">
        <div className="public-health-content">
          {options.map(({ text }, index) => croppedRectangle(text, index))}
        </div>
        <img className="earth-img nodrag-noselect" src={EarthImg} alt="Earth" />
      </div>
    </div>
  );
};

export default SulotionSection;
