/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import Option from "../../../static/backgrounds/Group 5.png";
import ManBody from "../../../static/backgrounds/Group 2095.png";

import "./index.css";

const SulotionSection = () => {
  const detectedItem = useRef();
  const [offset, setOffset] = useState(0);
  const [detected, setDetected] = useState(false);

  const setScroll = () => {
    setOffset(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  useEffect(() => {
    if (!detected) {
      detectScroll();
    }
  }, [offset]);

  const detectScroll = () => {
    if (detectedItem.current.offsetTop >= offset) {
      const top = detectedItem.current.offsetTop;

      if (top <= offset + 850) {
        setDetected(true);
        detectedItem.current.childNodes.forEach((child, index) => {
          child.childNodes[0].classList.add(
            "writing-animation",
            `writing-animation-${index}`
          );
        });
      }
    }
  };

  const options = [
    {
      src: Option,
      key: "Personalized Healthcare",
    },
    {
      src: Option,
      key: "Preventive Medicine",
    },
    {
      src: Option,
      key: "Enhanced care delivery",
    },
  ];
  //414px auto 432px
  return (
    <div className="solution-content-page">
      <div className="solution-content-title-container">
        <h1 className="solution-content-title">
          Our solution is creating digital twin of each individual to ensure
        </h1>
      </div>
      <div className="solution-content-parent">
        <div ref={detectedItem} className="solution-options-container">
          {options.map(({ src, key }, index) => (
            <div key={index} className={`option-text-${index}`}>
              <div>
                <span className="solution-option-text">{key}</span>
              </div>
              <img className="nodrag-noselect" src={src} alt="Option" />
            </div>
          ))}
        </div>
        <div>
          <img
            src={ManBody}
            alt="ManBody"
            className="man-body nodrag-noselect"
          />
        </div>
      </div>
    </div>
  );
};

export default SulotionSection;
