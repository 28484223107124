import React from "react";

import Diagram from "../../../static/backgrounds/Group 3.png";

import "./index.css";

const ProblemsSection = ({ pageRef }) => {
  return (
    <div className="problems-content-page" ref={pageRef}>
      <h1 className="problems-content-title">Problems we solve</h1>
      <div className="problems-content">
        <div className="problems-diagram">
          <div className="diagram-first-obj diagram-obj-config">
            <span>
              Globally, 1 in 3 of all adults suffer from multiple chronic
              conditions (MCCs)
            </span>
          </div>
          <div className="diagram-image">
            <img
              className="nodrag-noselect diagram-img-child"
              src={Diagram}
              alt="Diagram"
            />
          </div>
          <div className="diagram-second-obj diagram-obj-config">
            <span>About 32% of all global deaths are attributed to CVDs</span>
          </div>
        </div>
        <span className="diagram-conclusion">
          Yet, 80% of cardiovascular diseases could be prevented
        </span>
      </div>
    </div>
  );
};

export default ProblemsSection;
