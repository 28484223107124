import React from "react";

import "./index.css";

const HumanityContent = ({ learnMoreClick }) => {
  return (
    <div className="humanity-content-page">
      <div className="humanity-content-container">
        <div className="humanity-content">
          <div className="humanity-content-child">
            <span className="opening-content content-text-configuration">
              Helping humanity to overcome one of the biggest healthcare
              challenges:
            </span>
            <div>
              <span className="secondary-content content-text-configuration">
                Cardiovascular Diseases
              </span>
            </div>
          </div>
        </div>
        <div className="first-content-btns">
          <button
            onClick={() =>
              (window.location = "mailto:info@milies.net")
            }
            className="contact-us-btn first-content-btns-config nowrap"
          >
            Contact Us
          </button>
          <button
            onClick={learnMoreClick}
            className="learn-more-btn first-content-btns-config nowrap"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default HumanityContent;
