import React from "react";
import { Link, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import miliesLogo from "../../static/logo/milies-logo-light.svg";

import "./index.css";

const Header = () => {
  const pageItems = [
    {
      to: "#",
      name: "Contact Us",
      component: "",
    },
  ];

  return (
    <Router>
      <div className="header-parent">
        <div className="header-links-container">
          <Link to="/">
            <img
              src={miliesLogo}
              alt="logo"
              className="header-logo nodrag-noselect"
            />
          </Link>
          <ul className="header-links-list">
            {pageItems.map(({ to, name }, index) => (
              <li className="header-link-item-list nowrap" key={index}>
                <Link
                  className="header-link-item"
                  to={to}
                  onClick={() =>
                    (window.location = "mailto:info@milies.net")
                  }
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
          <Routes>
            {pageItems.map(({ to, component }, index) => (
              <Route exact path={to} element={component} key={index} />
            ))}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Header;
